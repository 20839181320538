<template>
  <Layout class="home">
  </Layout>
</template>

<script>
import Layout from '../components/layout.vue';

export default {
  components: {
    Layout,
  },
};
</script>
