<template>
  <div>
    <EditableInfo :club="club" class="pb-6"/>

    <div class="float-left btn-align font-md">
      <strong>{{ members.length }}</strong> {{ members.length | pluralize('Member') }}
    </div>

    <b-modal @ok="inviteAllNewMembers" id="modalInviteAllNewMembers"
             title="Invite new members?">
      Are you sure you want to invite all new members?
    </b-modal>

    <div class="actions">
      <label class="action">
        <div class="btn btn-primary">Import Members</div>
        <input ref="importMembersInput" type="file" @change="importMembers" class="d-none" accept="text/csv">
      </label>

      <label class="action">
        <b-btn v-b-modal="'modalInviteAllNewMembers'" variant="primary">Invite All New Members</b-btn>
      </label>
    </div>

    <ImportMembers ref="import" :club="club" class="mt-6"/>

    <Members :members="members" :club="club"/>
  </div>
</template>

<script>
import Members from '../../components/clubs/members'
import ImportMembers from '../../components/clubs/import-members'
import EditableInfo from '../../components/clubs/editable-info'

export default {
  props: ['club'],

  computed: {
    members() {
      return (this.club.memberships || []).map(membership => {
        let user = Object.assign({}, membership.user, membership, { user: undefined })
        delete user.user
        return user
      })
    },
  },

  methods: {
    importMembers() {
      this.$refs.import.import(this.$refs.importMembersInput)
    },

    inviteAllNewMembers() {
      this.$loading(() => this.$api.mutate(
        'inviteAllNewMembers', 'errors', { clubId: this.club.id }
      ).then(() => this.$alert('Members invited')))
    },
  },

  components: {
    EditableInfo,
    ImportMembers,
    Members,
  },
}
</script>

<style scoped lang="scss">
.actions {
  float: right;
  margin-bottom: map_get($spacers, 2);

  .action + .action {
    margin-left: map_get($spacers, 2);
  }
}
</style>