import Vue from 'vue'
import Vuex from 'vuex'

import global from 'forkable/store/modules/global'
import session from 'forkable/store/modules/session'
import alert from 'forkable/store/modules/alert'
import markets from './store/modules/markets'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,
    session,
    markets,
    alert,
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
})
