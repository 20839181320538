import GLOBAL from 'forkable/store/actions/global'
import { MARKETS } from '../actions'

const initialState = {
  currentMarketId: 1,
  all: [],
}

const getters = {
  market: state => state.all.find(market => market.id === state.currentMarketId),
  markets: state => state.all,
}

const actions = {
  [MARKETS.LOAD]: ({ dispatch, commit }) => new Promise((resolve, reject) => {
    dispatch(GLOBAL.LOADING, () =>
      $API.query('{ markets { id, name } }')
        .then(data => {
          const marketId = parseInt(localStorage.getItem('market-id') || 1, 10)
          commit(MARKETS.LOAD, data.markets)
          dispatch(MARKETS.SWITCH, marketId)
          resolve(data.markets)
        })
        .catch(e => reject(e)))
  }),

  [MARKETS.SWITCH]: ({ commit }, marketId) => {
    commit(MARKETS.SWITCH, marketId)
  },
}

const mutations = {
  [MARKETS.SWITCH]: (state, marketId) => {
    localStorage.setItem('market-id', marketId)
    state.currentMarketId = marketId
  },

  [MARKETS.LOAD](state, markets) {
    state.all = markets.sort((a, b) => a.name.localeCompare(b.name))
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
}
