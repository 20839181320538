<template>
  <div>
    <b-modal @ok="removeSelected" ok-title="Yes" ref="modalConfirmRemove" title="Remove meal?">
      Are you sure you want to remove a meal for
      {{ selectedPiece && selectedPiece.userFullName }} on {{ order.forDeliveryAt | date('DATE_MED')}}?
    </b-modal>

    <b-modal @ok="replaceSelected" ok-title="Yes" ref="modalConfirmReplace" title="Replace meal?">
      Are you sure you want to replace a meal for
      {{ selectedPiece && selectedPiece.userFullName }} on {{ order.forDeliveryAt | date('DATE_MED')}}?
    </b-modal>

    <b-table hover :items="pieces" :fields="fields" foot-clone class="pieces-table">
      <template slot="user" slot-scope="data">
        {{ data.item.userFullName }}
      </template>

      <template slot="item" slot-scope="data">{{ data.item.name }}</template>

      <template slot="attributes" slot-scope="data">
        <span v-for="(attribute) in data.item.attributes" :key="attribute.label">
          {{ attribute.label }}: <b>{{ attribute.value }}</b>
        </span>
        <div v-if="data.item.instructions"> Notes: <b>{{ data.item.instructions }}</b></div>
      </template>

      <template slot="price" slot-scope="data">
        {{ data.item.price | currency }}
      </template>

      <template slot="actions" slot-scope="data">
        <a @click.prevent="edit(data.item)" href="#"><i class="btr bt-pencil"></i></a>
        <a @click.prevent="confirmReplace(data.item)" href="#"><i class="btr bt-sync"></i></a>
        <a @click.prevent="confirmRemove(data.item)" href="#"><i class="btr bt-times"></i></a>
      </template>

      <template slot="FOOT_user" slot-scope="data">
        <div class="tally">
          <span v-for="line in order.tally" class="text-nowrap" :key="line.label">{{ line.label }}<br/></span>
          Service&nbsp;Fee<br/>
          <b>TOTAL</b>
        </div>
      </template>
      <template slot="FOOT_item" slot-scope="data"></template>
      <template slot="FOOT_attributes" slot-scope="data"></template>
      <template slot="FOOT_price" slot-scope="data">
        <div class="tally">
          <span v-for="line in order.tally" :key="line.label">{{ line.value | cents }}<br/></span>
          {{ order.serviceFee | cents}}<br/>
          <b>{{ order.total | cents}}</b><br/>
        </div>
      </template>
      <template slot="FOOT_actions" slot-scope="data"></template>
    </b-table>
  </div>
</template>

<script>
import GLOBAL from 'forkable/store/actions/global'
import OrderQuery from 'forkable/api/queries/order'

export default {
  props: ['order'],

  data() {
    return {
      selectedPiece: null,
    }
  },

  computed: {
    pieces() { return this.order.pieces || [] },
    fields() {
      return [
        { key: 'user', sortable: false },
        { key: 'item', sortable: false },
        { key: 'attributes', sortable: false, class: 'attributes' },
        { key: 'price', sortable: false, class: 'text-right' },
        { key: 'actions', sortable: false, class: 'actions' },
      ]
    },
  },

  methods: {
    edit(piece) {
      this.$emit('edit', piece)
    },

    confirmReplace(piece) {
      this.selectedPiece = piece
      this.order.state === 'preordered' ? this.replaceSelected() : this.$refs.modalConfirmReplace.show()
    },

    replaceSelected() {
      this.$emit('replace', this.selectedPiece)
    },

    confirmRemove(piece) {
      this.selectedPiece = piece
      this.$refs.modalConfirmRemove.show()
    },

    removeSelected() {
      const piece = this.selectedPiece

      this.$loading(() => this.$api.mutate(
        'removePiece',
        `order { ${OrderQuery.mealManagement} } errors`,
        { orderId: this.order.id, pieceId: this.selectedPiece.id },
      ).then(data => {
        if (data.errors) return this.$store.dispatch(GLOBAL.LOAD_ERROR, data.errors)
        this.$emit('remove', data.order, piece)
      }))
    },
  },
}
</script>

<style lang="scss">
.pieces-table {
  th {
    font-size: $font-size-xs !important;
  }

  td {
    font-size: $font-size-sm;
  }

  .attributes {
    font-size: $font-size-xxs;
  }

  .tally {
    font-size: $font-size-sm;
  }

  .actions {
    a {
      color: $primary;

      &:hover {
        color: $secondary;
      }
    }

    a + a {
      margin-left: 1.5rem;
    }

    @include media-breakpoint-down(md) {
      a {
        display: block;
      }

      a + a {
        margin-left: 0;
      }
    }
  }
}
</style>
