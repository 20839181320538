import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import Login from './components/login'
import Clubs from './views/Clubs'
import Club from './views/Club'

Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  linkActiveClass: 'active',
  routes: [
    { path: '/',      name: 'root',  component: Home },
    { path: '/login', name: 'login', component: Login, meta: { skipAuthentication: true } },
    { path: '/clubs', name: 'clubs', component: Clubs },
    { path: '/clubs/:clubId', name: 'club', component: Club },
  ],
})

export default router
