<template>
  <transition name="fade">
    <b-alert class="site-alert" :show="visible" :variant="variant" dismissible @dismissed="visible = false">
      {{ content }}
    </b-alert>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      variant: 'info',
      visible: false,
      content: '',
      hideTimeout: null,
    }
  },

  computed: {
    ...mapGetters({
      alert: 'siteAlert',
    }),
  },

  created() {
    this.$store.watch(
      () => this.$store.getters.siteAlert,
      this.show,
    )
  },

  methods: {
    show() {
      this.visible = true
      this.variant = this.alert.variant || 'info'
      this.content = this.alert.content
      if (this.hideTimeout) clearTimeout(this.hideTimeout)
      this.hideTimeout = setTimeout(this.hide, 5000)
    },

    hide() {
      this.hideTimeout = null
      this.visible = false
    },
  },
}
</script>

<style lang="scss" scoped>
.site-alert {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 10000;
}
</style>
