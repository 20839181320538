<template>
  <Layout class="club">
    <ul class="nav nav-tabs mb-4">
      <li class="nav-item">
        <a href="#meals" class="nav-link" :class="{ active: currentSection === 'meals' }"
           @click.prevent="() => switchTab('meals')">Manage Meals</a>
      </li>

      <li class="nav-item">
        <a href="#members" class="nav-link" :class="{ active: currentSection === 'members' }"
           @click.prevent="() => switchTab('members')">Manage Members</a>
      </li>
    </ul>

    <ClubMembers :club="club"
                 v-if="currentSection === 'members' || loadedSections.includes('members')"
                 v-show="currentSection === 'members'"></ClubMembers>

    <ClubMeals :club="club"
               v-if="currentSection === 'meals' || loadedSections.includes('meals')"
               v-show="currentSection === 'meals'"></ClubMeals>
  </Layout>
</template>

<script>
import MealClubQuery from 'forkable/api/queries/meal-club'
import Layout from '../components/layout.vue'
import ClubMembers from '../views/Club/Members'
import ClubMeals from '../views/Club/Meals'

export default {
  data() {
    return {
      clubId: this.$route.params.clubId,
      club: {},
      loadedSections: [],
      currentSection: 'info',
    }
  },

  created() {
    this.loadClub()
    this.$store.watch(
      () => this.$store.getters.market,
      () => { this.$router.push({ name: 'clubs' }) },
    )
  },

  computed: {
    members() {
      return this.club.members || []
    },
  },

  methods: {
    loadClub() {
      this.$loading(() =>
        this.$api.query('mealClub', { id: this.clubId }, MealClubQuery.withMembers).then(data => {
          const tab = this.$route.hash.substr(1)
          if (tab) this.switchTab(tab)
          this.club = data.mealClub
        }))
    },

    switchTab(tab) {
      if (!this.loadedSections.includes(tab)) this.loadedSections.push(tab)
      window.history.pushState(null, null, `#${tab}`)
      this.currentSection = tab
    },
  },

  components: {
    Layout,
    ClubMembers,
    ClubMeals,
  },
}
</script>
