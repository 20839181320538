<template>
  <div>
    <confirm-dialog @ok="toggleAutoOrder" :auto-confirm="5" ref="confirmAutoOrder">
      Confirm auto-order change?
    </confirm-dialog>

    <confirm-dialog @ok="updateCustomAllowance" :auto-confirm="5" ref="confirmCustomAllowance">
      Confirm custom allowance change?
    </confirm-dialog>

    <confirm-dialog @ok="removeUser" size="md" ok="Remove Membership" ref="confirmRemoveUser">
      <p>Are you sure you want to remove {{(this.userForUpdate || {}).fullName}}'s membership?</p>
      Remove remaining meals: <live-input as="toggle" v-model="removeUserMeals" inline class="ml-2"/>
    </confirm-dialog>

    <b-table hover responsive :items="currentMembers" :fields="fields" sort-by="nameReverse"
             class="members-table table-xs">
      <template slot="count" slot-scope="data">
        #{{ data.index + 1 }}<br/>
        ({{ data.item.id }})
      </template>

      <template slot="name" slot-scope="data">
        {{ data.item.nameReverse || '&lt;Unknown&gt;' }}
        <span v-if="!data.item.name"><i>Unknown</i></span>
      </template>

      <template slot="inviteLink" slot-scope="data">
        <a v-if="data.item.invitationLink" :href="data.item.invitationLink">Invitation</a>
      </template>

      <template slot="phone" slot-scope="data">
        <a :href="`tel:${data.item.phone}`" v-if="data.item.phone">{{ data.item.phone | phone }}</a>
      </template>

      <template slot="dietaryRestrictions" slot-scope="data">
        {{ data.item.restrictions.map(r => r.split('_').join(' ')) | capitalizeEach | join(', ') }}
      </template>

      <template slot="invited" slot-scope="data">
        {{ data.item.invitationLink ? 'yes' : 'no' }}
      </template>

      <template slot="autoOrder" slot-scope="data">
        <live-input as="toggle" on-label="On" off-label="Off" v-model="autoOrder[data.item.id]"
                    @input="(value) => confirmAutoOrder(data.item, value)"/>
      </template>

      <template slot="active" slot-scope="data">
        {{ data.item.active ? 'yes' : 'no' }}
      </template>

      <template slot="claimed" slot-scope="data">
        {{ data.item.claimed ? 'yes' : 'no' }}
      </template>

      <template slot="cc" slot-scope="data">
        <span :class="{ ['text-danger']: club.copay && !data.item.creditCards.length > 0 }">
          {{ data.item.creditCards.length > 0 ? 'yes' : 'no' }}
        </span>
      </template>

      <template slot="startDate" slot-scope="data">
        {{ data.item.startDate | date('DATE_SHORT') }}
      </template>

      <template slot="endDate" slot-scope="data">
        {{ data.item.endDate | date('DATE_SHORT') }}
      </template>

      <template slot="customAllowance" slot-scope="data">
        <b-input size="sm" v-model="customAllowance[data.item.id]"
                 @change="(value) => confirmCustomAllowance(data.item, value)"/>
      </template>

      <template slot="delete" slot-scope="data">
        <b-btn @click="() => confirmRemoveUser(data.item)" variant="link secondary"><i class="btr bt-times"/></b-btn>
      </template>
    </b-table>
  </div>
</template>

<script>
import LiveInput from 'forkable/lib/components/live-input'
import ConfirmDialog from 'forkable/lib/components/confirm-dialog'
import { ErrorSwallow } from 'forkable/lib/errors'

export default {
  props: ['club', 'members'],

  data() {
    let autoOrder = {}, customAllowance = {}
    this.members.forEach(user => {
      autoOrder[user.id] = user.mealClubAutoOrder
      customAllowance[user.id] = user.copayAllowance ? user.copayAllowance / 100 : null
    })

    return {
      autoOrder,
      customAllowance,
      userForUpdate: null,
      userAttributesForUpdate: {},
      removeUserMeals: true,
      currentMembers: this.members,
    }
  },

  computed: {
    fields() {
      let fields = [
        { key: 'count', sortable: false },
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'inviteLink', sortable: false },
        { key: 'phone', sortable: true },
        { key: 'dietaryRestrictions', sortable: false },
        { key: 'autoOrder', sortable: true },
        { key: 'invited', sortable: true },
        { key: 'active', sortable: true },
        { key: 'claimed', sortable: true },
        { key: 'cc', label: 'CC', sortable: true },
        { key: 'startDate', sortable: true },
        { key: 'endDate', sortable: true },
      ]
      if (this.club.copay) fields.push({ key: 'customAllowance', sortable: false })
      fields.push({ key: 'delete', label: 'Delete?', sortable: false })
      return fields
    },
  },

  methods: {
    confirmAutoOrder(user, autoOrder) {
      this.userForUpdate = user
      this.userAttributesForUpdate = { mealClubAutoOrder: autoOrder }
      this.$refs.confirmAutoOrder.show()
    },

    confirmCustomAllowance(user, value) {
      this.userForUpdate = user
      this.userAttributesForUpdate = { copayAllowance: parseInt(parseFloat(value) * 100) }
      this.$refs.confirmCustomAllowance.show()
    },

    updateCustomAllowance() {
      this.$loading(() => this.updateUser().catch(data => {
        this.customAllowance[this.userForUpdate.id] = !this.userAttributesForUpdate.copayAllowance
        this.$api.defaultAlert(data.errors)
        throw new ErrorSwallow
      }))
    },

    toggleAutoOrder() {
      this.$loading(() => this.updateUser().catch(data => {
        this.autoOrder[this.userForUpdate.id] = !this.userAttributesForUpdate.mealClubAutoOrder
        this.$api.defaultAlert(data.errors)
        throw new ErrorSwallow
      }))
    },

    updateUser() {
      return this.$api.mutate(
        'updateUser',
        'user { mealClubAutoOrder }',
        { userId: this.userForUpdate.id, attributes: this.userAttributesForUpdate }
      ).then(data => {
        this.$alert(`User ${this.userForUpdate.fullName} updated.`)
        return data
      })
    },

    confirmRemoveUser(user) {
      this.userForUpdate = user
      this.removeUserMeals = true
      this.$refs.confirmRemoveUser.show()
    },

    removeUser() {
      this.$loading(() => this.$api.mutate(
        'removeMembership',
        'errors',
        { clubId: this.club.id, userId: this.userForUpdate.id, removeMeals: this.removeUserMeals })
      .then(() => {
        this.currentMembers = this.currentMembers.filter(user => user.id !== this.userForUpdate.id)
        this.$alert(`Membership for user ${this.userForUpdate.fullName} removed.`)
      }))
    },
  },

  components: { LiveInput, ConfirmDialog },
}
</script>

<style lang="scss">
.members-table {
  .live-input--toggle {
    width: 80px;
    padding-top: 0;

    label {
      margin: 0;
    }
  }
}
</style>