<template>
  <div class="delivery-details">
    <h4 class="btn-align">
      {{ day | date('DATE_HUGE') }}
      <div class="float-none float-sm-right">
        <b-btn variant="primary" @click="addPiece">Add Meal</b-btn>
        <b-btn variant="outline-primary" class="ml-2" @click="removeDelivery">Remove Delivery</b-btn>
      </div>
    </h4>

    <SelectMeal ref="selectMeal" :club="club" :delivery="delivery" :users="users" :selectableUser="true"
                @add="onPieceAdd"
                @edit="onPieceAdd"
                @replace="onPieceReplace" />

    <div v-for="(order, orderIndex) in orders" :key="order.id"
            class="d-block mb-3">
      <div class="delivery-status mb-3" v-if="orderIndex === 0">
        State: <b>{{ delivery.state | capitalize }}</b><br/>
        Delivery ID: {{ delivery.id }}
      </div>

      <b-card no-body class="order-details">
        <b-card-header class="btn-sm-align py-2">
          {{ order.menu.name }}
          <div class="info">
            <span>Order ID: <b>{{ order.id }}</b></span>
            <span>State: <b>{{ order.state | capitalize }}</b></span>
          </div>
        </b-card-header>
      </b-card>
      <OrderPieces :order="order" @edit="editPiece" @replace="replacePiece" @remove="onPieceRemove" class="text-left" />
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import SelectMeal from 'forkable/components/deliveries/select-meal'
import OrderPieces from '../orders/pieces'

export default {
  props: ['club', 'day', 'delivery', 'users'],

  data() {
    return {
      operation: null,
    }
  },

  computed: {
    orders() {
      return this.delivery.orders
    },
  },

  methods: {
    replaceOrders(newOrders) {
      this.delivery.orders = this.orders.map(order => {
        const replace = newOrders.find(newOrder => newOrder.id === order.id)
        return replace || order
      })
    },

    checkModifiable() {
      if (this.delivery.state === 'preordered') {
        this.$alert('The order is frozen', 'danger')
        return false
      }
      return true
    },

    removeDelivery() {
      if (!this.checkModifiable()) return

      const date = DateTime.fromISO(this.delivery.forDeliveryAt).toLocaleString(DateTime.DATE_FULL)
      this.$confirm('Remove Delivery', `Are you sure you want to remove this delivery for ${date}?`)
        .then(() => {
          this.$api.mutate('removeDelivery', 'errors', { deliveryId: this.delivery.id })
            .then(() => this.$emit('remove', this.delivery))
            .catch(data => this.$api.defaultAlert(data.errors))
        })
    },

    addPiece() {
      if (!this.checkModifiable()) return
      this.$refs.selectMeal.openForAdd()
    },

    editPiece(piece) {
      this.$refs.selectMeal.openForEdit(this.users.find(user => user.id === piece.userId), piece)
    },

    replacePiece(piece) {
      this.checkModifiable()
      this.$refs.selectMeal.openForReplace(this.users.find(user => user.id === piece.userId), piece)
    },

    onPieceAdd(order, piece) {
      this.replaceOrders([order])
      this.$refs.selectMeal.close()
      const date = DateTime.fromISO(order.forDeliveryAt).toLocaleString(DateTime.DATE_FULL)
      const user = this.users.find(user => user.id === piece.userId)
      this.$alert(`You've added a meal for ${date} for ${user.fullName}`)
    },

    onPieceReplace(delivery, piece) {
      this.replaceOrders(delivery.orders)
      this.$refs.selectMeal.close()
      const date = DateTime.fromISO(delivery.forDeliveryAt).toLocaleString(DateTime.DATE_FULL)
      const user = this.users.find(user => user.id === piece.userId)
      this.$alert(`You've replaced a meal for ${date} for ${user.fullName}`)
    },

    onPieceRemove(order, piece) {
      const date = DateTime.fromISO(this.delivery.forDeliveryAt).toLocaleString(DateTime.DATE_DAYF_MONTH)
      this.$alert(`You've removed a meal for ${date} for ${piece.userFullName}`)
      this.replaceOrders([order])
    },
  },

  components: {
    SelectMeal, OrderPieces,
  },
}
</script>

<style lang="scss">
.delivery-status {
  font-size: $font-size-sm;
}

.delivery-details {
  .order-details {
    border: 0;
    background-color: $gray-100;
    font-size: $font-size-md;
  }

  .order-details .card-header {
    border: 0;

    .info {
      font-size: $font-size-sm;
      float: right;

      span + span {
        margin-left: 1.5rem;
      }
    }
  }
}
</style>