import Vue from 'vue'
import BoostrapVue from 'bootstrap-vue'
import graphql from 'graphql.js'
import VueScrollTo from 'vue-scrollto'
import Filters from 'forkable/lib/filters'
import Loading from 'forkable/lib/loading'
import SiteAlert from 'forkable/lib/alert'
import Confirm from 'forkable/lib/confirm'
import LegacyMenu from 'forkable/legacy/menu'
import LegacyMCM from 'forkable/lib/legacy-mcm'

import Api from 'forkable/api'
import App from './App'

import store from './store'
import router from './router'

window.$API = new Api(process.env.VUE_APP_API_BASE, store, 'mcm')
Vue.prototype.$api = window.$API

window.$GQL = graphql(`${process.env.VUE_APP_API_BASE}/graphql`, { asJSON: true })
Vue.prototype.$gql = window.$GQL

Vue.config.productionTip = false

Vue.use(BoostrapVue)
Vue.use(VueScrollTo)
Vue.use(Filters)
Vue.use(Loading)
Vue.use(SiteAlert)
Vue.use(Confirm)
Vue.use(LegacyMenu)
Vue.use(LegacyMCM)

window.ForkableVue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
