<template>
  <Layout class="clubs">
    <b-modal @ok="revealDeliveries" :title="`Reveal ALL deliveries in ${market.name}`" id="modalRevealDeliveries">
      This function will reveal all deliveries for all active meal clubs in {{ market.name }}.
      You should only do this once you've checked and double checked the meals for all clubs.
    </b-modal>

    <CreateClub ref="createClub"/>

    <div class="border-bottom pb-3 mb-5">
      <div class="float-lg-right">
        <b-form-checkbox class="h1-align" v-model="showInactive">Show Inactive</b-form-checkbox>
        <b-btn v-b-modal="'modalRevealDeliveries'" variant="primary">Reveal all Deliveries</b-btn>
        <b-btn @click="$refs.createClub.show()" variant="primary" class="ml-2">Add a New Club</b-btn>
      </div>
      <h1>{{ market.name }} Meal Clubs</h1>
    </div>

    <b-table responsive hover :items="filteredClubs" :fields="fields" sort-by="name" class="table-xs table-alt">
      <template slot="name" slot-scope="data">
        <router-link :to="{ name: 'club', params: { clubId: data.item.id } }">
          {{ data.item.name }}
        </router-link>
      </template>

      <template slot="division" slot-scope="data">{{ data.item.division.name }}</template>

      <template slot="active" slot-scope="data">
        <b-badge :variant="data.item.active ? 'primary' : 'secondary'">{{ data.item.active ? 'Yes' : 'No' }}</b-badge>
      </template>

      <template slot="contact" slot-scope="data">
        <a :href="`mailto:${data.item.contactUser.email}`" target="_blank" v-if="data.item.contactUser">
          {{ data.item.contactUser.email }}
          <i class="btr bt-external-link ml-2 text-primary"/>
        </a>
      </template>

      <template slot="hasCc" slot-scope="data">
        <span v-if="data.item.invoice">invoice</span>
        <span v-else-if="data.item.hasCc">yes</span>
        <span v-else class="text-danger">no</span>
      </template>

      <template slot="copay" slot-scope="data">
        <span v-if="data.item.copay">{{ data.item.copayAllowance | currency }}</span>
        <span v-else>-</span>
      </template>

      <template slot="members" slot-scope="data">
        <router-link class="link-primary"
               :to="{ name: 'club', hash: '#members', params: { clubId: data.item.id, preloadClub: data.item } }">
          <i class="btr bt-user bt-fw"></i>
        </router-link>
        ({{ data.item.membersCount }})
      </template>

      <template slot="meals" slot-scope="data">
        <router-link class="link-primary"
               :to="{ name: 'club', hash: '#meals', params: { clubId: data.item.id, preloadClub: data.item } }">
          <i class="btr bt-calendar bt-fw"></i>
        </router-link>
      </template>
    </b-table>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '../components/layout.vue'
import CreateClub from '../components/clubs/create-club'

export default {
  created() {
    this.loadClubs()
    this.$store.watch(
      () => this.$store.getters.market,
      this.reloadClubs,
    )
  },

  data() {
    return {
      showInactive: false,
      inactiveLoaded: false,
      clubs: [],
    }
  },

  computed: {
    ...mapGetters(['market']),

    fields() {
      let fields = [
        { key: 'id', label: 'Club ID', sortable: true },
        { key: 'name', sortable: true },
        { key: 'division', label: 'Route', sortable: true },
      ]
      if (this.showInactive) fields.push({ key: 'active', sortable: true })
      fields = fields.concat([
        { key: 'contact', label: 'Contact Info', sortable: false },
        { key: 'hasCc', label: 'Has CC?', sortable: true },
        { key: 'copay', sortable: true },
        { key: 'members', label: 'View Members', sortable: false },
        { key: 'meals', label: 'View Meals', sortable: false, class: 'text-right' },
      ])
      return fields
    },

    filteredClubs() {
      if (this.showInactive && !this.inactiveLoaded) this.loadClubs(true)
      if (this.showInactive) return this.clubs

      return this.clubs.filter(club => club.active)
    },
  },

  methods: {
    loadClubs(inactive) {
      const marketId = this.$store.state.markets.currentMarketId
      this.$loading(() => {
        const fields = 'id name active hasCc invoice copay copayAllowance membersCount division { name } ' +
          'contactUser { email }'
        return this.$api.query(`{ mealClubs(marketId: ${marketId}, active: ${!inactive}) { ${fields} } }`)
          .then(data => {
            this.inactiveLoaded = inactive
            this.clubs = this.clubs.concat(data.mealClubs)
          })
      })
    },

    reloadClubs() {
      this.showInactive = false
      this.inactiveLoaded = false
      this.clubs = []
      return this.loadClubs()
    },

    revealDeliveries() {
      this.$loading(() => this.$api.mutate('revealAllDeliveries', 'errors', { marketId: this.market.id }).then(() =>
        this.$alert(`All deliveries in ${this.market.name} have been revealed.`)))
    },
  },

  components: {
    Layout, CreateClub,
  },
}
</script>
