<template>
  <div id="app" v-if="!requireLogin && (!loading || isLogin)">
    <iframe :style="{ display: currentSite === 'external' ? 'block' : 'none' }" class="legacy-mcm"
            :src="legacyMcmUrl"/>
    <router-view :key="$route.fullPath" :style="{ display: currentSite === 'internal' ? 'block' : 'none' }"/>
  </div>

  <b-container v-else-if="requireLogin">
    <Login/>
  </b-container>

  <b-container v-else>
    <b-jumbotron class="alert-info text-center mt-5">
      <img src="./assets/logo.png" alt="Forkable Logo" class="img-fluid">
      <h1 class="my-5 display-3">Loading</h1>
      <i class="btr bt-gear bt-spin bt-5x"></i>
    </b-jumbotron>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import SESSION from 'forkable/store/actions/session'
import { MARKETS } from './store/actions'
import Login from './components/login.vue'

export default {
  data() {
    return {
      loading: !this.$store.getters.user,
      isLogin: this.$route.name === 'login',
      requireLogin: this.$store.getters.user,
      legacyMcmUrl: process.env.VUE_APP_LEGACY_MCM_URL,
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'currentSite',
    }),
  },

  created() {
    this.$store.watch(() => this.$store.state.session.user, newValue => {
      this.requireLogin = !newValue
      this.$store.dispatch(MARKETS.LOAD)
    }, { deep: true })

    this.validateSession()
      .then(() => this.$store.dispatch(MARKETS.LOAD))
      .then(() => {
        this.loading = false
        this.requireLogin = false
      })
      .catch(e => {
        if (e.response && e.response.status === 403) return
        throw e
      })
  },

  methods: {
    validateSession() {
      return this.$store.dispatch(SESSION.VALIDATE)
        .catch(e => {
          this.loading = false
          this.requireLogin = true
          throw e
        })
    },
  },

  components: {
    Login,
  },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/dist/bootstrap-vue';
@import '~forkable/styles/forkable';
@import '~flatpickr/dist/flatpickr.css';

.legacy-mcm {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}
</style>
