<template>
  <b-modal @ok.prevent="create" title="Add a new club" ref="modal" @shown="$refs.name.focus()">
    <live-input v-model="name" label="Club Name" :errors="errorsFor('name')" ref="name"/>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import LiveInput from 'forkable/lib/components/live-input'
import editableModel from 'forkable/lib/mixins/editable-model'

export default {
  mixins: [editableModel],

  data() {
    return {
      name: '',
    }
  },

  computed: {
    ...mapGetters(['market']),
  },

  methods: {
    show() {
      this.name = ''
      this.errorAttributes = {}
      this.$refs.modal.show()
    },

    create() {
      this.$loading(() =>
        this.$api.mutate(
          'createClub',
          'club { id } errorAttributes',
          { attributes: { name: this.name, marketId: this.market.id } },
        ).then(data => {
          if (Object.keys(data.errorAttributes).length === 0) {
            this.$router.push({ name: 'club', params: { clubId: data.club.id }, hash: '#members' })
          } else {
            this.errorAttributes = data.errorAttributes
          }
        }))
    }
  },

  components: {
    LiveInput,
  },
}
</script>