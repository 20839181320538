<template>
  <div>
    <ClubName :club="club"/>
    <GenerateMeals :club="club" @generated="onGenerateMeals" ref="generateMeals"/>

    <b-modal @ok="revealHiddenOrders" id="modalConfirmReveal" title="Reveal all orders?">
      You should only reveal all orders once you have double-checked that all the generated meals are correct
    </b-modal>

    <b-modal @ok="() => sendCalendars('this')" id="modalSendThisWeekCalendars"
             title=" Are you sure you want to (re)send calendars for this week?">
      Meal calendars are normally sent every Friday via an automatic background job.
      Are you sure you want to manually send them?
    </b-modal>

    <b-modal @ok="() => sendCalendars('next')" id="modalSendNextWeekCalendars"
             title="Are you sure you want to (re)send calendars for next week?">
      Meal calendars are normally sent every Friday via an automatic background job.
      Are you sure you want to manually send them?
    </b-modal>

    <b-card no-body class="bg-light border-0">
      <b-card-header class="border-0 py-3">
        <b-btn v-b-modal="'modalConfirmReveal'" variant="outline-primary" class="bg-white">
          Reveal Hidden Orders
        </b-btn>

        <div class="float-lg-right">
          <b-btn v-b-modal="'modalSendThisWeekCalendars'"
                 variant="outline-primary" class="d-block d-sm-inline-block mt-2 mt-lg-0 bg-white mr-2">
            <i class="btr bt-envelope mr-1"></i>
            Send This Week's Calendars
          </b-btn>

          <b-btn v-b-modal="'modalSendNextWeekCalendars'"
                 variant="outline-primary" class="d-block d-sm-inline-block mt-2 mt-lg-0 bg-white">
            <i class="btr bt-envelope mr-1"></i>
            Send Next Week's Calendars
          </b-btn>
        </div>
      </b-card-header>
    </b-card>

    <div class="meals-week-nav mt-5 mb-4">
      <h5 class="mb-5">
        <a class="prev" href="#" @click.prevent="prevWeek">&lang;</a>
        {{ weekLabel }} <b>{{ formattedDate }} </b>
        <a class="next" href="#" @click.prevent="nextWeek">&rang;</a>
      </h5>

      <div class="btn-group btn-group-full-width">
        <b-btn variant="outline-light" class="py-2" v-for="(_selected, day) in selectedDates" :key="day"
               @click="() => toggleDay(day)"
               :disabled="!deliveries[day]"
               :class="{ active: selectedDates[day] }">
          <b-checkbox v-model="selectedDates[day]" :disabled="!deliveries[day]"
                      class="d-none d-md-inline-flex" />
          <span class="d-inline d-sm-none">{{ day | weekdayShort }}</span>
          <span class="d-none d-sm-inline">{{ day | weekdayLong }}</span>
          <span class="d-none d-md-inline"> ({{ (deliveries[day] || []).length }})</span>
        </b-btn>
      </div>
    </div>

    <div v-for="(selected, day) in selectedDates" v-if="selected && deliveries[day]" :key="day" class="delivery-block">
      <hr/>
      <DeliveryDetails v-for="delivery in deliveries[day]" :key="delivery.id"
                       :club="club"
                       :day="day"
                       :users="users"
                       :delivery="delivery"
                       @remove="onDeliveryRemove"/>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { isSameDay } from 'forkable/utilities/date-time'
import DeliveryQuery from 'forkable/api/queries/delivery'
import UserQuery from 'forkable/api/queries/user'
import GenerateMeals from '../../components/meals/generate'
import DeliveryDetails from '../../components/deliveries/details'
import ClubName from '../../components/clubs/club-name'

function datesForWeek(date) {
  return {
    [date.toISODate()]: true,
    [date.plus({ day: 1 }).toISODate()]: true,
    [date.plus({ day: 2 }).toISODate()]: true,
    [date.plus({ day: 3 }).toISODate()]: true,
    [date.plus({ day: 4 }).toISODate()]: true,
  }
}

export default {
  props: ['club'],

  data() {
    const today = DateTime.local()
    const thisWeek = today.startOf('week')
    const date = today.weekday === 7 ? thisWeek.plus({ week: 1 }) : thisWeek

    return {
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      date,
      currentDay: 'Monday',
      selectedDates: datesForWeek(date),
      flatDeliveries: [],
      users: [],
    }
  },

  created() {
    this.loadDeliveries()
  },

  watch: {
    date(newDate) {
      this.selectedDates = datesForWeek(newDate)
      this.loadDeliveries()
    },
  },

  computed: {
    deliveries() {
      return this.mapDeliveriesByDay(this.flatDeliveries)
    },

    formattedDate() {
      const span = this.date.day + 4
      return this.date.setLocale(navigator.language).toFormat(`LLLL d-${span}, yyyy`)
    },

    weekLabel() {
      const { weeks } = this.date.diffNow('weeks')
      if (weeks < 1 && weeks > 0) return 'Next Week: '
      if (weeks < 0 && weeks > -1) return 'This Week: '
      if (weeks < 0 && weeks > -2) return 'Last Week: '
      return 'Week of '
    },
  },

  methods: {
    toggleDay(day) {
      this.selectedDates[day] = !this.selectedDates[day]
    },

    loadDeliveries() {
      const args = `mealClubId: ${this.club.id}, from: "${this.date.toFormat('yyyy-MM-dd')}"`
      this.$loading(() =>
        this.$api.query(`{ deliveries(${args}) { ${DeliveryQuery.mealManagement} } ` +
                          `users(clubId: ${this.club.id}) { ${UserQuery.mealManagement} } }`)
          .then(data => {
            this.flatDeliveries = data.deliveries
            this.users = data.users
          }))
    },

    mapDeliveriesByDay(deliveries) {
      const map = {}
      deliveries.forEach(delivery => {
        const date = DateTime.fromISO(delivery.forDeliveryAt).toISODate()
        if (!map[date]) map[date] = []
        map[date].push(delivery)
      })
      return map
    },

    revealHiddenOrders() {
      this.$loading(() => this.$api.mutate(
        'revealHiddenOrders', `deliveries { ${DeliveryQuery.mealManagement} } errors`,
        { clubId: this.club.id },
      ).then(data => this.replaceDeliveries(data.deliveries)))
    },

    replaceDeliveries(newDeliveries) {
      let deliveries = this.flatDeliveries.map(delivery => {
        const replace = newDeliveries.find(newDelivery => newDelivery.id === delivery.id)
        return replace || delivery
      })
      deliveries = deliveries.concat(newDeliveries.filter(newDelivery =>
        !this.flatDeliveries.find(delivery => delivery.id === newDelivery.id)))
      this.flatDeliveries = deliveries
    },

    sendCalendars(week) {
      this.$loading(() => this.$api.mutate('sendMealCalendars', 'errors', { clubId: this.club.id, week }))
    },

    prevWeek() {
      this.date = this.date.minus({ weeks: 1 })
    },

    nextWeek() {
      this.date = this.date.plus({ weeks: 1 })
    },

    onGenerateMeals(deliveries, week) {
      this.$alert('Meals have been generated')
      isSameDay(week, this.date) ? this.replaceDeliveries(deliveries) : this.date = week
    },

    onDeliveryRemove(removed) {
      this.$refs.generateMeals.reload()
      this.flatDeliveries = this.flatDeliveries.filter(delivery => delivery.id !== removed.id)
    },
  },

  components: {
    ClubName,
    GenerateMeals,
    DeliveryDetails,
  },
}
</script>

<style lang="scss">
.meals-week-nav {
  text-align: center;

  .prev, .next {
    color: $primary;
    font-weight: bold;
    padding: 0 0.5rem;
    text-decoration: none;
  }

  .prev {
    margin-right: 5rem;
  }

  .next {
    margin-left: 5rem;
  }
}

.delivery-block {
  padding-bottom: 1rem;
}
</style>