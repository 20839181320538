<template>
  <div>
    <MenuBar/>
    <SiteAlert/>

    <b-modal title="Server Error" ref="modalServerError" ok-only>
      There was an unexpected server error.
    </b-modal>

    <b-modal title="Error" ref="modalLoadError" ok-only>
      <div v-for="(error, index) in loadErrors" :key="`errors${index}`">
        {{ error }}
      </div>
    </b-modal>

    <b-container class="mt-5">
      <slot></slot>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GLOBAL from 'forkable/store/actions/global'
import MenuBar from './layout/menu-bar'
import SiteAlert from './layout/site-alert'

export default {
  computed: {
    ...mapGetters({
      loadErrors: 'loadError',
    }),
  },

  created() {
    this.$store.watch(
      () => this.$store.getters.serverError,
      () => {
        if (this.$store.getters.serverError !== null && this.$refs.modalServerError) {
          this.$store.dispatch(GLOBAL.SERVER_ERROR, null)
          this.$refs.modalServerError.show()
        }
      },
    )
    this.$store.watch(
      () => this.$store.getters.loadError,
      () => {
        if (this.$store.getters.loadError !== null && this.$refs.modalLoadError) {
          this.$store.dispatch(GLOBAL.LOAD_ERROR, null)
          this.$refs.modalLoadError.show()
        }
      },
    )
  },

  components: {
    MenuBar, SiteAlert,
  },
}
</script>
