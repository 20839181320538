<template>
  <b-container>
    <b-row class="mt-md-5">
      <b-col sm="10" md="8" lg="6" class="mx-auto mt-sm-5 mt-3">
        <b-card>
          <img src="../assets/logo.png" class="img-fluid" />
          <p class="h4 text-center my-4">MCM Login</p>
          <b-form @submit.prevent="login">
            <b-form-group>
              <b-form-input v-model="email" type="email" required autofocus placeholder="E-mail"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input v-model="password"
                            type="password"
                            :state="errors.password === undefined"
                            required
                            placeholder="Password"></b-form-input>
              <b-form-invalid-feedback>{{(errors.password || []).join(', ')}}</b-form-invalid-feedback>
            </b-form-group>

            <b-btn type="submit" size="lg" variant="primary" :block="true">Sign In</b-btn>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Api from 'forkable/api/index'
import SESSION from 'forkable/store/actions/session'

export default {
  data() {
    return {
      email: '',
      password: '',
      errors: {},
    }
  },

  methods: {
    login() {
      this.$loading(() =>
        this.$api.sessions.create({ email: this.email, password: this.password })
          .then(data => this.$store.dispatch(SESSION.SUCCESS, data.session.user))
          .then(() => this.$router.push(this.$route.query.redirect || { name: 'root' }))
          .catch(e => Api.assignErrorsOrThrow(this, e)))
    },
  },
}
</script>
