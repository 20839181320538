<template>
  <div>
    <b-modal @ok="confirmImport" ref="modalConfirmImport" title="Warning">
      Are you sure you want to proceed? The recommended club size is 50 members or less.
    </b-modal>

    <b-alert :show="!!importErrors.length" dismissible variant="danger">
      <ul>
        <li v-for="error in importErrors" :key="error">
          {{ error }}
        </li>
      </ul>
      <span>Fix the {{ importErrors.length | pluralize('issue') }} and repeat.&nbsp;</span>
      <span v-if="importCount > 0">
        {{ importCount | pluralize('user', true) }} {{ importCount | pluralize('has') }} been imported successfully.
      </span>
    </b-alert>
    <b-alert :show="importCount > 0 && importErrors.length === 0" dismissible variant="success">
      {{ importCount | pluralize('user', true) }}
      {{ importCount | pluralize('has') }} been imported. Check the list before inviting them.
    </b-alert>
  </div>
</template>

<script>
export default {
  props: ['club'],

  data() {
    return {
      importErrors: [],
      importCount: null,
      csv: null,
    }
  },

  methods: {
    import(input) {
      const file = input.files[0]
      if (!file) return

      const reader = new FileReader()
      reader.onload = e => {
        this.csv = e.target.result.split('\n').filter(line => line.replace(/[\s,]/g, '').length > 0)
        input.value = ''
        if (this.csv.length === 1) this.csv = this.csv[0].split('\r')
        this.csv.length > 51 ? this.$refs.modalConfirmImport.show() : this.confirmImport()
      }
      reader.readAsText(file)
    },

    confirmImport() {
      this.uploadMembers(this.csv.join('\n'))
    },

    uploadMembers(csv) {
      const query = '($input: ImportMembersInput!) ' +
        '{ clubImportMembers(input: $input) { imported { id name email phone } errors }}'
      this.$loading(() =>
        this.$api.mutate(query, { input: { csv, clubId: this.club.id } })
          .then(data => {
            const result = data.clubImportMembers
            this.importCount = result.imported.length
            this.importErrors = result.errors
            this.club.members = this.club.members.concat(result.imported.map(member =>
              Object.assign({}, member, { _rowVariant: 'success' })))
          }))
    },
  },
}
</script>
