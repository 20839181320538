<template>
  <b-row>
    <b-col md="10">
      <h1 class="mb-4">
        {{ club.name }}
      </h1>
    </b-col>
    <b-col class="text-lg-right h1-align font-sm">
      {{ club.division && club.division.name }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['club']
}
</script>