<template>
  <div class="card mb-4 font-sm">
    <b-modal @ok="() => generate()" ref="modalConfirm" title="Generate Meals" ok-title="Yes">
      It looks like the meals for these days already exist. Are you sure you want to proceed?
    </b-modal>

    <div class="card-body text-md-left text-center">
      <b class="btn-sm-align mr-sm-3 mr-0">Generate Meals for:</b>
      <b-form-radio-group v-model="week" class="d-block d-sm-inline-block mb-1">
        <b-radio value="0">This Week</b-radio>
        <b-radio value="1">Next Week</b-radio>
      </b-form-radio-group>

      <b-btn @click="confirm" variant="primary" :disabled="!loaded || selected.indexOf(true) === -1"
             class="float-md-right">
        Generate Meals
      </b-btn>

      <b-alert :show="errors.length > 0" variant="danger" class="mt-3 mb-0">
        {{ errors.join(', ') }}
      </b-alert>

      <b-btn-group class="mt-4">
        <b-checkbox v-for="day in dates" :key="`day${day.weekday}`" v-model="selected[day.weekday - 1]">
          <span class="d-none d-md-inline">{{ day | weekdayLong }},</span>
          <span class="d-inline d-md-none">{{ day | weekdayShort }}</span>

          <span class="d-none d-md-inline"> {{ day | date('DATE_SHORT') }}</span>
          <span class="d-inline d-md-none"> {{ day | date('DATE_TINY') }}</span>
        </b-checkbox>
      </b-btn-group>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { isSameDay } from 'forkable/utilities/date-time'
import { filterUnique } from 'forkable/utilities/array'
import DeliveryQuery from 'forkable/api/queries/delivery'

export default {
  props: ['club'],

  data() {
    const today = DateTime.local()
    const thisWeek = today.startOf('week')

    return {
      loaded: false,
      thisWeek,
      week: 1,
      selected: [false, false, false, false, false],
      errors: [],
      existingDates: [],
    }
  },

  computed: {
    dates() {
      const start = this.thisWeek.plus({ week: this.week })
      return [
        start,
        start.plus({ day: 1 }),
        start.plus({ day: 2 }),
        start.plus({ day: 3 }),
        start.plus({ day: 4 }),
      ]
    },
  },

  created() {
    this.reload()
  },

  methods: {
    reload() {
      this.loadExistingDates()
    },

    loadExistingDates() {
      const mealClubId = this.club.id,
            from = this.thisWeek.toFormat('yyyy-MM-dd'),
            to = this.thisWeek.plus({ week: 1 }).endOf('week').toFormat('yyyy-MM-dd')
      this.loaded = false

      this.$loading(() =>
        this.$api.query('deliveries', { mealClubId, from, to }, 'forDeliveryAt')
          .then(data => {
            this.existingDates = filterUnique(data.deliveries.map(delivery => delivery.forDeliveryAt))
              .map(date => DateTime.fromISO(date))
            this.loaded = true
          }))
    },

    confirm() {
      const selected = this.dates.filter((date, index) => this.selected[index])
      const existing = this.existingDates.find(existing => selected.find(date => existing.day === date.day))
      existing ? this.$refs.modalConfirm.show() : this.generate()
    },

    generate() {
      this.errors = []
      this.$loading(() => {
        const dates = this.dates.filter((_, index) => this.selected[index])
        return this.$api.mutate('generateMeals',
                                `deliveries { ${DeliveryQuery.mealManagement} } errors`,
                                { dates, clubId: this.club.id })
          .then(data => {
            this.errors = data.errors
            if (this.errors.length > 0) return
            this.$emit('generated', data.deliveries, this.thisWeek.plus({ week: this.week }))
            this.loadExistingDates()
          })
      })
    },
  },
}
</script>