<template>
  <b-row v-if="attributes">
    <b-col md="7">
      <b-form-group>
        <h1>
          <live-input v-model="attributes.name" :errors="errorsFor('name')" label="Club Name" :maxlength="60"
                      :dirty="changed.includes('name')"/>
        </h1>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.deliveryDaysAndTime" as="textarea" :errors="errorsFor('deliveryDaysAndTime')"
                    label="Standard Delivery Days & Notes" :dirty="changed.includes('deliveryDaysAndTime')"/>
      </b-form-group>
    </b-col>

    <b-col class="font-sm">
      <div class="border-bottom pb-2 clearfix">
        <div class="float-right">
          <b-btn v-if="isDirty" @click="reset" variant="outline-primary" class="mr-2 mb-2">Cancel</b-btn>
          <b-btn @click="save" variant="primary" class="mb-2" :disabled="!isDirty">Save</b-btn>
        </div>

        <div>
          <b-badge variant="danger" class="badge-tag mr-3 mb-2" v-if="attributes.churnRisk">Churn Risk!</b-badge>
          <b-badge variant="inactive" class="badge-tag mb-2" v-if="!attributes.active">Inactive</b-badge>
        </div>
      </div>

      <b-form-group class="mt-5" v-if="divisions && divisions.length > 0">
        <live-input v-model="attributes.divisionId" as="select" :options="divisions" :errors="errorsFor('divisionId')"
                    label="Route" :dirty="changed.includes('divisionId')"/>
      </b-form-group>
    </b-col>

    <b-col sm="12" class="mb-4"><hr></b-col>

    <b-col md="7">
      <b-form-group>
        <live-input v-model="attributes.deliveryAddress" as="address" :errors="errorsFor('deliveryAddress')"
                    label="Address" hint="This field is exposed to the user and exported to onfleet."
                    :dirty="changed.includes('deliveryAddress')"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.deliveryNotes" :errors="errorsFor('deliveryNotes')"
                    label="Delivery Notes" hint="This field is exposed to the admin and exported to onfleet."
                    :dirty="changed.includes('deliveryNotes')"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.deliveryWindow" as="time-range" :errors="errorsFor('deliveryWindow')"
                    label="Preferred Delivery Window*" :dirty="changed.includes('deliveryWindow')"/>
      </b-form-group>

      <small class="text-muted">
        *24 hour time. Miniumum 30 minute window. You may enter just one part of range when i.e. the customer
        requests "after 12:30" or "before 12".
      </small>
    </b-col>

    <b-col>
      <b-form-group>
        <live-input v-model="attributes.deliveryApt" :errors="errorsFor('deliveryApt')"
                    label="Floor/Suite" :dirty="changed.includes('deliveryApt')"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="deliveryDaysSelected" as="checkboxes"
                    :options="deliveryDaysOptions"
                    :errors="errorsFor('deliveryDays')" label="Delivery Days**"
                    hint="Set Delivery Days for Meal Generation. This field is exposed to the user."
                    :dirty="changed.includes('deliveryDays')"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.mealPlanningNotes" as="textarea" :errors="errorsFor('mealPlanningNotes')"
                    label="Meal Planning Notes**" :dirty="changed.includes('mealPlanningNotes')"/>
      </b-form-group>

      <small class="text-muted">
        **Set Delivery Days for Meal Generation. Delivery Days and Meal Planning Notes are exposed in MP.
      </small>
    </b-col>
    <b-col sm="12" class="mb-4 mt-3"><hr></b-col>

    <b-col md="7">
      <b-form-group>
        <live-input label="Payment Info" as="readonly"
                    :value="club.invoice ? 'Payment by invoice' : club.creditCardInfo"
                    hint="This information is visible to the admin."/>
      </b-form-group>

      <b-form-group>
        <b-row>
          <b-col md="6">
            <live-input v-model="attributes.copay" as="toggle" label="Copay?"
                        hint="This information is visible to the admin."/>
          </b-col>

          <b-col v-if="attributes.copay">
            <live-input v-model="attributes.clubCoversAllFees" as="toggle" label="Club covers taxes and fees?"/>
          </b-col>
        </b-row>
      </b-form-group>

      <div v-if="attributes.copay">
        <b-form-group>
          <b-row>
            <b-col sm="4" md="6" lg="4">
              <live-input v-model="attributes.copayAllowance" label="Default allowance"
                          :dirty="changed.includes('copayAllowance')"/>
            </b-col>
          </b-row>
        </b-form-group>

        <b-row class="daily-allowances">
          <b-col>
            <b-form-group><live-input v-model="attributes.dailyAllowances[1]" label="Monday"
                                      :dirty="changed.includes('dailyAllowances')"/></b-form-group>
          </b-col>
          <b-col>
            <b-form-group><live-input v-model="attributes.dailyAllowances[2]" label="Tuesday"
                                      :dirty="changed.includes('dailyAllowances')"/></b-form-group>
          </b-col>
          <b-col>
            <b-form-group><live-input v-model="attributes.dailyAllowances[3]" label="Wednesday"
                                      :dirty="changed.includes('dailyAllowances')"/></b-form-group>
          </b-col>
          <b-col>
            <b-form-group><live-input v-model="attributes.dailyAllowances[4]" label="Thursday"
                                      :dirty="changed.includes('dailyAllowances')"/></b-form-group>
          </b-col>
          <b-col>
            <b-form-group><live-input v-model="attributes.dailyAllowances[5]" label="Friday"
                                      :dirty="changed.includes('dailyAllowances')"/></b-form-group>
          </b-col>
        </b-row>
      </div>

      <b-form-group>
        <live-input v-model="attributes.freeDelivery" as="toggle" label="Free Delivery?"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.autoOrder" as="toggle" label="Default Auto-Order"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.clearItemsFromUnclaimedAccounts" as="toggle"
                    label="Clear meals for unclaimed accounts***"/>
      </b-form-group>

      <small class="text-muted">
        ***If a large club & a lot of members are unclaimed, set to YES. Check with the admin.
      </small>
    </b-col>

    <b-col>
      <b-form-group>
        <live-input v-model="attributes.churnRisk" as="toggle" label="Churn Risk"/>
      </b-form-group>

      <b-form-group v-if="attributes.churnRisk">
        <live-input v-model="attributes.churnRiskNotes" label="Notes" :dirty="changed.includes('churnRiskNotes')"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.churn" as="toggle" label="Churn"/>
      </b-form-group>

      <b-form-group v-if="attributes.churn">
        <live-input v-model="attributes.churnedReason" label="Primary Reason" as="select" :options="churnReasons"
                    :key="attributes.churnedReasonSecondary"
                    :dirty="changed.includes('churnedReason')"/>
      </b-form-group>

      <b-form-group v-if="attributes.churn && attributes.churnedReason">
        <live-input v-model="attributes.churnedReasonSecondary" label="Secondary Reason" as="select"
                    :key="attributes.churnedReason" :options="secondaryChurnReasons"
                    :dirty="changed.includes('churnedReasonSecondary')"/>
      </b-form-group>

      <b-form-group v-if="attributes.churn">
        <live-input v-model="attributes.churnDate" as="date" label="Date" :dirty="changed.includes('churnDate')"/>
      </b-form-group>

      <b-form-group v-if="attributes.churn">
        <live-input v-model="attributes.churnNotes" label="Notes" :dirty="changed.includes('churnNotes')"/>
      </b-form-group>

      <b-form-group>
        <live-input v-model="attributes.active" as="toggle" label="Active"/>
      </b-form-group>
    </b-col>

    <b-col sm="12" class="mb-4"><hr></b-col>

    <b-col md="7">
      <div v-if="availableUsers">
        <b-form-group>
          <live-input v-model="attributes.organizerUserId" as="select" :options="availableUsers" label="Organizer"
                      :dirty="changed.includes('organizerUserId')"/>
        </b-form-group>

        <b-form-group v-for="(userId, index) in assistantIds" :key="`${userId}/${assistantIds.join(',')}`"
                      class="removable">
          <live-input v-model="assistantIds[index]" as="select" label="Admin"
                      :options="availableAssistants.concat(availableUsers.filter(u => u.id === userId))"
                      :dirty="changed.includes('assistantIds')"/>
          <span class="remove" @click="removeAssistant(index)"><i class="btr bt-times"></i></span>
        </b-form-group>

        <b-form-group v-if="availableAssistants.length > 0 && assistantIds.length < 5">
          <b-btn @click="addAssistant" variant="link" size="sm" class="px-0 with-icon">
            <i class="btr bt-plus-circle"/>Admin
          </b-btn>
        </b-form-group>
      </div>
    </b-col>

    <b-col>
      <div v-if="availableUsers">
        <b-form-group>
          <live-input v-model="attributes.contactUserId" as="select" :options="availableUsers" label="Contact"
                      :errors="errorsFor('contactUserId')" :dirty="changed.includes('contactUserId')"/>
        </b-form-group>

        <b-form-group v-for="(userId, index) in accountantIds" :key="`${userId}/${accountantIds.join(',')}`"
                      class="removable">
          <live-input v-model="accountantIds[index]" as="select" label="Accountant"
                      :options="availableAccountants.concat(availableUsers.filter(u => u.id === userId))"
                      :dirty="changed.includes('accountantIds')"/>
          <span class="remove" @click="removeAccountant(index)"><i class="btr bt-times"></i></span>
        </b-form-group>

        <b-form-group v-if="availableAccountants.length > 0 && accountantIds.length < 5">
          <b-btn @click="addAccountant" variant="link" size="sm" class="px-0 with-icon">
            <i class="btr bt-plus-circle"/>Accountant
          </b-btn>
        </b-form-group>
      </div>
    </b-col>

    <b-col sm="7">
      <b-form-group>
        <live-input v-model="attributes.ccEmail" label="Additional receipt emails - comma separated"
                    :errors="errorsFor('ccEmail')" :dirty="changed.includes('ccEmail')"/>
      </b-form-group>
    </b-col>

    <b-col sm="12"><hr></b-col>

    <b-col class="font-sm">
      <div class="pb-2 clearfix">
        <div class="float-right">
          <b-btn v-if="isDirty" @click="reset" variant="outline-primary" class="mr-2 mb-2">Cancel</b-btn>
          <b-btn @click="save" variant="primary" class="mb-2" :disabled="!isDirty">Save</b-btn>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import LiveInput from 'forkable/lib/components/live-input'
import editableModel from 'forkable/lib/mixins/editable-model'
import MealClubQuery from 'forkable/api/queries/meal-club'

const PLAIN_ATTRIBUTES = [
  'name', 'active', 'divisionId', 'deliveryDaysAndTime', 'deliveryNotes', 'deliveryApt',
  'mealPlanningNotes', 'deliveryWindow', 'copay', 'clubCoversAllFees', 'copayAllowance',
  'freeDelivery', 'autoOrder', 'clearItemsFromUnclaimedAccounts', 'churn', 'churnDate', 'churnNotes',
  'churnedReason', 'churnedReasonSecondary', 'churnRisk', 'churnRiskNotes', 'organizerUserId', 'contactUserId',
  'ccEmail', 'assistantIds', 'accountantIds',
]

const OBJECT_ATTRIBUTES = [
  'deliveryAddress', 'deliveryDays',
]

const CHURN_REASONS = [
  'Forkable Costs',
  'Food Quality',
  'Delivery Time',
  'Lack Of Variety',
  'Bad Recommendations',
  'Company Closed',
  'Company Moved Outside Service Area',
  'Company Cut Lunch Benefit',
  '2pm cutoff time',
  'Prefer buffet, other service, chef',
  'System is too complicated or simple',
  'Other',
]

function originalAttributes(club) {
  let attrs = {}
  for (let attr of PLAIN_ATTRIBUTES) { attrs[attr] = club[attr] }
  for (let attr of OBJECT_ATTRIBUTES) { attrs[attr] = Object.assign({}, club[attr]) }
  attrs.dailyAllowances = {}
  Object.entries(club.dailyAllowances).forEach(([key, value]) => { attrs.dailyAllowances[key] = value.toString() })
  return attrs
}

export default {
  mixins: [editableModel],

  props: {
    club: { type: Object, required: true },
  },

  data() {
    let deliveryDaysSelected = []
    if (this.club.deliveryDays) {
      for (let i = 1; i < 6; i++) {
        if (this.club.deliveryDays[i]) deliveryDaysSelected.push(i)
      }
    }

    return {
      original: originalAttributes(this.club),
      attributes: originalAttributes(this.club),
      divisions: [],
      deliveryDaysSelected,
      deliveryDaysOptions: [
        { text: 'Monday',    value: 1 },
        { text: 'Tuesday',   value: 2 },
        { text: 'Wednesday', value: 3 },
        { text: 'Thursday',  value: 4 },
        { text: 'Friday',    value: 5 },
      ],
      deliveryWindow: [this.club.deliveryWindowStart, this.club.deliveryWindowEnd],
      availableUsers: null,
      assistantIds: this.club.assistants.map(user => user.id),
      accountantIds: this.club.accountants.map(user => user.id),
    }
  },

  computed: {
    ...mapGetters({
      market: 'market',
    }),

    availableAssistants() {
      if (!this.availableUsers) return
      return this.availableUsers.filter(user => !this.assistantIds.includes(user.id))
    },

    availableAccountants() {
      if (!this.availableUsers) return
      return this.availableUsers.filter(user => !this.accountantIds.includes(user.id))
    },

    churnReasons() {
      return CHURN_REASONS.filter(reason => reason !== this.attributes.churnedReasonSecondary)
    },

    secondaryChurnReasons() {
      return CHURN_REASONS.filter(reason => reason !== this.attributes.churnedReason)
    }
  },

  watch: {
    deliveryDaysSelected(newDays) {
      if (newDays.selfUpdate) {
        delete newDays.selfUpdate
        return
      }

      const daysInt = newDays.map(day => parseInt(day))
      this.attributes.deliveryDays = {
        1: daysInt.includes(1),
        2: daysInt.includes(2),
        3: daysInt.includes(3),
        4: daysInt.includes(4),
        5: daysInt.includes(5),
      }
    },

    assistantIds(newIds) {
      this.attributes.assistantIds = newIds
    },

    accountantIds(newIds) {
      this.attributes.accountantIds = newIds
    },
  },

  created() {
    this.$loading(() =>
      this.$api.query('divisions', { marketId: this.market.id }, 'id name')
        .then(data => this.divisions = data.divisions))

    this.$loading(() =>
      this.$api.query('users', { clubId: this.club.id, tiedClubs: true }, 'id fullName mealClubs { name }')
        .then(data => this.availableUsers = data.users.map(user => {
          return {
            id: user.id,
            text: `${user.fullName} (${user.mealClubs.map(club => club.name).sort().join(', ')})`
          }
        })))
  },

  methods: {
    save() {
      this.$loading(() =>
        this.$api.mutate(
          'updateClub',
          `club { ${MealClubQuery.full} } errorAttributes`,
          { clubId: this.club.id, attributes: this.changedAttributes },
        ).then(data => {
          if (Object.keys(data.errorAttributes).length === 0) {
            this.original = originalAttributes(data.club)
            this.attributes = originalAttributes(data.club)
          }
          this.errorAttributes = data.errorAttributes
          this.$alert('Club changes saved')
        }))
    },

    reset() {
      this.resetDeliveryDays()
      this.assistantIds = this.original.assistantIds
      this.accountantIds = this.original.accountantIds
      this.attributes = originalAttributes(this.original)
      this.errorAttributes = {}
    },

    resetDeliveryDays() {
      let selected = []
      for (let i = 1; i < 6; i++) {
        if (this.original.deliveryDays[i]) selected.push(i)
      }
      selected.selfUpdate = true
      this.deliveryDaysSelected = selected
    },

    addAssistant(index) {
      this.assistantIds.push(null)
    },

    removeAssistant(index) {
      this.assistantIds.splice(index, 1)
    },

    addAccountant(index) {
      this.accountantIds.push(null)
    },

    removeAccountant(index) {
      this.accountantIds.splice(index, 1)
    },
  },

  components: {
    LiveInput,
  }
}
</script>

<style lang="scss" scoped>
  @include media-breakpoint-down(md) {
    .daily-allowances {
      .col {
        width: 50%;
        flex-basis: auto;
      }
    }
  }

  .form-group.removable {
    position: relative;

    .live-input {
      width: calc(100% - 20px);
    }

    .remove {
      position: absolute;
      cursor: pointer;
      right: 0;
      top: 50%;
      margin-top: -($font-size-xxs / 2);
      font-size: $font-size-xxs;

      &:hover {
        color: $danger;
      }
    }
  }
</style>