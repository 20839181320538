<template>
  <b-navbar toggleable="lg" type="dark">
    <b-navbar-brand :to="{ name: 'root' }">
      <img src="../../assets/logo-icon-light.svg" height="20" class="mr-2 align-text-bottom">
      MCM
    </b-navbar-brand>

    <b-navbar-toggle target="menu-bar"></b-navbar-toggle>

    <b-collapse id="menu-bar" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{ name: 'clubs' }">Clubs</b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav class="menu__markets" v-if="market && markets">
        <b-nav-item-dropdown :text="market.name">
          <b-dropdown-item v-for="mkt in markets"
                           :key="`market${mkt.id}`"
                           :class="{ active: mkt.id === market.id }"
                           @click="switchMarket(mkt)">{{ mkt.name }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item @click="navigateLegacy">Legacy</b-nav-item>
      </b-navbar-nav>
    </b-collapse>

    <div class="loader" v-if="globalLoading"></div>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import GLOBAL from 'forkable/store/actions/global'
import { MARKETS } from '../../store/actions'

export default {
  computed: {
    ...mapGetters(['market', 'markets', 'globalLoading']),
  },

  methods: {
    switchMarket(market) {
      this.$store.dispatch(MARKETS.SWITCH, market.id)
    },

    navigateLegacy() {
      this.$store.dispatch(GLOBAL.NAVIGATE_TO_EXT)
    },
  },
}
</script>

<style lang="scss">
.navbar-brand img {
  margin-bottom: 2px; // Tweak alignment
}

.loader {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 3px;
  left: 0;
  top: 0;
  background-color: $yellow;

  &:before, &:after {
    content: "";
    display: inline;
    position: absolute;
    width: 0;
    height: 100%;
    left: 50%;
    text-align: center;
  }

  &:before {
    background-color: $primary;
    animation: loading 3s linear infinite;
  }

  &:after {
    background-color: $yellow;
    animation: loading 3s linear 1s infinite;
  }

  @keyframes loading {
    from { left: 50%; width: 0; z-index:100; }
    50% { left: 0; width: 100%; z-index: 10; }
    to { left: 0; width: 100%; }
  }
}
</style>
